import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import Link from 'next/link'

import GooglePlayButton from '../buttons/GooglePlayButton'
import Highlight from '../Highlight'
import Logo from '../Logo'
import BottomRow from './BottomRow'
import FacebookSocial from './FacebookSocial'
import styles from './Footer.module.css'
import InstagramSocial from './InstagramSocial'

const Footer = () => (
  <div className={styles.wrapper}>
    <div className={styles.padding}>
      <Logo />
    </div>
    <div className={classNames(styles.rows, styles.padding)}>
      <div className={classNames(styles.column, styles.left)}>
        <em>
          <div className={styles.text}>
            <strong>
              <Trans>
                India&apos;s Largest Pregnancy &amp; Parenting Platform,
                <br />
                <Highlight>trusted by 1 Crore+ women.</Highlight>
              </Trans>
            </strong>
            <br />
            <Trans>Healofy is helping moms who are ready to start their heavenly journey of motherhood.</Trans>
          </div>
        </em>
        <div className={styles.socialWrapper}>
          <FacebookSocial />
          <InstagramSocial />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.linksWrapper}>
          <div className={styles.links}>
            <em>
              <strong>
                <Trans>User Content</Trans>
              </strong>
              <Link href='/lifestages/pregnant'>
                <a>
                  <Trans>Pregnancy</Trans>
                </a>
              </Link>
              <Link href='/lifestages/pfp'>
                <a>
                  <Trans>Planning For Pregnancy</Trans>
                </a>
              </Link>
              <Link href='/lifestages/mother'>
                <a>
                  <Trans>Already a Parent</Trans>
                </a>
              </Link>
            </em>
          </div>
          <div className={styles.links}>
            <em>
              <strong>
                <Trans>Read</Trans>
              </strong>
              <Link href='/blogs'>
                <a>
                  <Trans>Blogs</Trans>
                </a>
              </Link>
              <Link href='/qa'>
                <a>
                  <Trans>Question &amp; Answers</Trans>
                </a>
              </Link>
            </em>
          </div>
        </div>
        <div className={classNames(styles.linksWrapper, styles.desktopOnly)}>
          <div className={classNames(styles.downloadApp, styles.links)}>
            <strong>
              <Trans>Download App:</Trans>
            </strong>
          </div>
          <div className={classNames(styles.playIcon, styles.links)}>
            <GooglePlayButton section='footer' />
          </div>
        </div>
      </div>
    </div>
    <BottomRow />
  </div>
)

export default Footer
