import { Trans } from '@lingui/macro'
import Link from 'next/link'

import Facebook from './Facebook'
import styles from './Footer.module.css'

const FacebookSocial = () => (
  <Link href='https://www.facebook.com/healofy'>
    <a className={styles.social}>
      <div className={styles.socialContent}>
        <div className={styles.icon}>
          <Facebook />
        </div>
        <div>
          <strong>815K</strong>
          <div className={styles.socialText}>
            <Trans>Followers</Trans>
          </div>
        </div>
      </div>
      <div className={styles.arrow}>→</div>
    </a>
  </Link>
)

export default FacebookSocial
