import { Trans } from '@lingui/macro'
import Link from 'next/link'

import styles from './Footer.module.css'
import Instagram from './Instagram'

const InstagramSocial = () => (
  <Link href='https://www.instagram.com/healofyapp'>
    <a className={styles.social}>
      <div className={styles.socialContent}>
        <div className={styles.icon}>
          <Instagram />
        </div>
        <div>
          <strong>185K</strong>
          <div className={styles.socialText}>
            <Trans>Followers</Trans>
          </div>
        </div>
      </div>
      <div className={styles.arrow}>→</div>
    </a>
  </Link>
)

export default InstagramSocial
