import { Trans } from '@lingui/macro'
import classNames from 'classnames'

import GooglePlayButton from '../buttons/GooglePlayButton'
import styles from './Footer.module.css'

const shopPageUrl = process.env.NEXT_PUBLIC_MYSHOP_URL as string
const aboutPage = `${shopPageUrl}/pages/about`
const privacyPage = `${shopPageUrl}/pages/privacy-policy`
const tncPage = `${shopPageUrl}/pages/terms-of-use`
const returnsPage = `${shopPageUrl}/pages/privacy-policy`

const BottomRow = () => (
  <div className={classNames(styles.rows, styles.desktopBottomRow, styles.padding)}>
    <div className={classNames(styles.linksWrapper, styles.mobileOnly)}>
      <div className={styles.downloadApp}>
        <strong>
          <Trans>Download App:</Trans>
        </strong>
      </div>
      <div className={classNames(styles.playIcon, styles.links)}>
        <GooglePlayButton section='footer' />
      </div>
    </div>
    <div className={classNames(styles.column, styles.ulLinks)}>
      <a className={styles.linkLi} href={aboutPage}>
        <Trans>About Us</Trans>
      </a>
      <div className={styles.linkDot}>·</div>
      <a className={styles.linkLi} href={tncPage}>
        <Trans>Terms &amp; Conditions</Trans>
      </a>
      <div className={styles.linkDot}>·</div>
      <a className={styles.linkLi} href={privacyPage}>
        <Trans>Privacy</Trans>
      </a>
      <div className={styles.linkDot}>·</div>
      <a className={styles.linkLi} href={returnsPage}>
        <Trans>Return &amp; Refund Policy</Trans>
      </a>
    </div>
    <div className={styles.column}>
      <div className={styles.center}>
        <Trans>Copyright reserved @healofy.com</Trans>
      </div>
    </div>
  </div>
)

export default BottomRow
