import * as log from '../utils/log'

export const extractQAId = (slug: string) => decodeURIComponent(slug).split('-').slice(-1)[0]
export const extractSlug = (slug: string) => decodeURIComponent(slug).split('-').slice(0, -1).join('-')

const defaultLink = process.env.NEXT_PUBLIC_DOMAIN + '/qa'

export const toQAUrl = (slug: string, id: string) => {
  try {
    if (slug) {
      return `/qa/${encodeURIComponent(slug)}-${id}`
    }
    return `/qa/${id}`
  } catch (error) {
    log.error(error, slug, id)
  }
  return defaultLink
}
