import Link from 'next/link'
import { FC } from 'react'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import GooglePlayIcon from './GooglePlayIcon'

interface Props {
  section: string
}

const GooglePlayButton: FC<Props> = props => (
  <Link href={process.env.NEXT_PUBLIC_PLAYSTORE_URL as string}>
    <a onClick={() => pushCleverEvent('click', { keyAction: 'gPlay2', section: props.section })}>
      <GooglePlayIcon />
    </a>
  </Link>
)
export default GooglePlayButton
