const Facebook = () => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 34 35' version='1.1'>
    <title>facebook (1)</title>
    <g id='OLD' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='010_Mobile_Homescreen' transform='translate(-18.000000, -4058.000000)' fill='#475993' fillRule='nonzero'>
        <g id='Group-10' transform='translate(18.000000, 3920.000000)'>
          <g id='Group-66' transform='translate(0.000000, 138.000000)'>
            <g id='facebook-(1)' transform='translate(0.000165, 0.733333)'>
              <path
                d='M29.1722403,0 L4.54327791,0 C2.03414474,0 0,2.03406227 0,4.54327791 L0,29.1724052 C0,31.6816209 2.03406227,33.7158481 4.54327791,33.7158481 L16.6901646,33.7158481 L16.7108665,21.6675221 L13.5807596,21.6675221 C13.1739801,21.6675221 12.8438223,21.3386015 12.8422553,20.931822 L12.8272443,17.0481998 C12.8256773,16.6391935 13.1568248,16.3068088 13.5658311,16.3068088 L16.6902471,16.3068088 L16.6902471,12.5542435 C16.6902471,8.19942689 19.3499019,5.82819625 23.2346787,5.82819625 L26.4224375,5.82819625 C26.8302892,5.82819625 27.1610243,6.1588489 27.1610243,6.56678305 L27.1610243,9.84147319 C27.1610243,10.2492424 26.8305366,10.5798126 26.4228499,10.58006 L24.466564,10.5809672 C22.3539006,10.5809672 21.9448117,11.5848844 21.9448117,13.0581816 L21.9448117,16.3068913 L26.5870628,16.3068913 C27.0293901,16.3068913 27.3725793,16.6931338 27.3204535,17.1324095 L26.860146,21.0160316 C26.816103,21.3876756 26.5009562,21.667687 26.1267553,21.667687 L21.9655136,21.667687 L21.9448117,33.7158481 L29.1724052,33.7158481 C31.6815384,33.7158481 33.7156007,31.6817858 33.7156007,29.1726526 L33.7156007,4.54327791 C33.7156007,2.03406227 31.6814559,0 29.1722403,0 Z'
                id='Path'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Facebook
