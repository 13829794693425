const Instagram = () => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 35 35' version='1.1'>
    <title>2284970</title>
    <defs>
      <radialGradient cx='40%' cy='100%' fx='40%' fy='100%' r='100%' id='radialGradient-1f'>
        <stop stopColor='#FFDD55' offset='10%' />
        <stop stopColor='#FF543E' offset='50%' />
        <stop stopColor='#C837AB' offset='100%' />
      </radialGradient>
      <linearGradient x1='0%' y1='0%' x2='20%' y2='100%' id='linearGradient-2f'>
        <stop stopColor='#3771C8' offset='10%' />
        <stop stopColor='#6600FF' stopOpacity='0' offset='50%' />
      </linearGradient>
    </defs>
    <g id='OLD' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='010_Mobile_Homescreen' transform='translate(-18.000000, -4104.000000)' fillRule='nonzero'>
        <g id='Group-10' transform='translate(18.000000, 3920.000000)'>
          <g id='Group-68' transform='translate(0.000000, 184.000000)'>
            <g id='2284970' transform='translate(0.733333, 0.733333)'>
              <rect id='b' fill='#000000' x='0' y='0' width='33.7333333' height='33.7333333' rx='4' />
              <g id='b-link' fill='url(#radialGradient-1f)'>
                <rect id='b' x='0' y='0' width='33.7333333' height='33.7333333' rx='4' />
              </g>
              <g id='b-link' fill='url(#linearGradient-2f)'>
                <rect id='b' x='0' y='0' width='33.7333333' height='33.7333333' rx='4' />
              </g>
              <g id='Group' transform='translate(5.732031, 5.732031)' fill='#FFFFFF'>
                <path
                  d='M15.9442708,0 L6.325,0 C2.83179896,0 0,2.83179896 0,6.325 L0,15.9442708 C0,19.4374719 2.83179896,22.2692708 6.325,22.2692708 L15.9442708,22.2692708 C19.4374719,22.2692708 22.2692708,19.4374719 22.2692708,15.9442708 L22.2692708,6.325 C22.2692708,2.83179896 19.4374719,0 15.9442708,0 Z M6.325,1.9765625 L15.9442708,1.9765625 C18.3458466,1.9765625 20.2927083,3.92342428 20.2927083,6.325 L20.2927083,15.9442708 C20.2927083,18.3458466 18.3458466,20.2927083 15.9442708,20.2927083 L6.325,20.2927083 C3.92342428,20.2927083 1.9765625,18.3458466 1.9765625,15.9442708 L1.9765625,6.325 C1.9765625,3.92342428 3.92342428,1.9765625 6.325,1.9765625 Z'
                  id='Rectangle'
                />
                <path
                  d='M11.1346354,5.40260417 C7.96892197,5.40260417 5.40260417,7.96892197 5.40260417,11.1346354 C5.40260417,14.3003489 7.96892197,16.8666667 11.1346354,16.8666667 C14.3003489,16.8666667 16.8666667,14.3003489 16.8666667,11.1346354 C16.8666667,7.96892197 14.3003489,5.40260417 11.1346354,5.40260417 Z M11.1346354,7.37916667 C13.2087235,7.37916667 14.8901042,9.0605473 14.8901042,11.1346354 C14.8901042,13.2087235 13.2087235,14.8901042 11.1346354,14.8901042 C9.0605473,14.8901042 7.37916667,13.2087235 7.37916667,11.1346354 C7.37916667,9.0605473 9.0605473,7.37916667 11.1346354,7.37916667 Z'
                  id='Oval'
                />
                <path
                  d='M17.1302083,3.75546875 C16.3660706,3.75546875 15.7466146,4.37492477 15.7466146,5.1390625 C15.7466146,5.90320023 16.3660706,6.52265625 17.1302083,6.52265625 C17.8943461,6.52265625 18.5138021,5.90320023 18.5138021,5.1390625 C18.5138021,4.37492477 17.8943461,3.75546875 17.1302083,3.75546875 Z M17.1302083,4.54609375 C17.4576959,4.54609375 17.7231771,4.8115749 17.7231771,5.1390625 C17.7231771,5.4665501 17.4576959,5.73203125 17.1302083,5.73203125 C16.8027207,5.73203125 16.5372396,5.4665501 16.5372396,5.1390625 C16.5372396,4.8115749 16.8027207,4.54609375 17.1302083,4.54609375 Z'
                  id='Oval'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default Instagram
